import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Container from '../components/container';
import Layout from '../components/layout';

class NotFoundPage extends React.Component {
    render() {
        const links = get(this, 'props.data.allContentfulLink.nodes') || [{}];

        return (
            <Layout links={links}>
                <Container>
                    <h1>NOT FOUND</h1>
                    <p>
                        You just hit a route that doesn&#39;t exist... the
                        sadness.
                    </p>
                </Container>
            </Layout>
        );
    }
}

export default NotFoundPage;

export const notFoundPage = graphql`
    query NotFoundPage {
        contentfulPage(title: { eq: "privacy-policy" }) {
            body {
                raw
            }
        }
        allContentfulLink {
            nodes {
                title
                link
                shouldHideIcon
            }
        }
    }
`;
